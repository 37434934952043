import { PageWrapper } from '../../components';
import ContactIllustration from '../../assets/img/contact-image.png';
import EyeIllustration from '../../assets/img/eye-illustration.png';
import style from './contact.module.scss';
import { SocialIcon } from '../../assets/icon';

const Contact = () => {
  return (
    <PageWrapper>
      <div className={style.wrapper}>
        <img src={ContactIllustration} className={style.contactIllustration} />
        <div className={style.info}>
          <h2>İLETİŞİM</h2>
          <h3>
            <span>T:</span> +90 (312) 426 97 12
          </h3>
          <h3>
            <span>T:</span> +90 533 762 31 17
          </h3>
          <h3>
            <span>EMAIL:</span> info@ucansupurge.org
          </h3>
          <h3>
            <span>ADRES:</span> Gaziosmanpaşa Mahallesi, <br />
            Nenehatun Caddesi, Şairler Sokak, <br />
            No: 7/4 Çankaya, <br />
            06680 - Ankara/Turkey <br />
          </h3>
          <div className={style.socialMedia}>
            <a href="/">
              <SocialIcon type={'facebook'} />
            </a>
            <a href="/">
              <SocialIcon type={'twitter'} />
            </a>
            <a href="/">
              <SocialIcon type={'instagram'} />
            </a>
          </div>
          <div className={style.map}>
            <iframe
              src="https://yandex.com.tr/map-widget/v1/?ll=32.868296%2C39.894789&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgozNzE5ODA0MzQyEixUw7xya2l5ZSwgQW5rYXJhLCDDh2Fua2F5YSwgxZ5haXJsZXIgU29rLiwgNyIKDSl5A0IVRJQfQg%2C%2C&z=19.27"
              width="560"
              height="400"
              frameborder="1"
              allowfullscreen="true"
            ></iframe>
          </div>
        </div>
        <img src={EyeIllustration} className={style.eyeIllustration} />
      </div>
    </PageWrapper>
  );
};

export default Contact;
