import style from './support.module.scss';

import Illustration from '../../assets/img/support-illustration.png';

import Canada from '../../assets/img/brands/canada.png';
import Goethe from '../../assets/img/brands/goethe.png';
import HaberTurk from '../../assets/img/brands/haber-turk.png';
import Pencere from '../../assets/img/brands/pencere.png';
import Sverige from '../../assets/img/brands/sverige.png';
import Sweden from '../../assets/img/brands/sweden.png';

const Support = () => {
  return (
    <div className={style.support}>
      <img src={Illustration} className={style.illustration} />
      <div className={style.wrapper}>
        <h3>DESTEKLEYENLER</h3>
        <div className={style.logoWrapper}>
          <div className={style.row}>
            <img src={Canada} />
            <img src={Goethe} />
            <img src={HaberTurk} />
            <img src={Pencere} />
            <img src={Sverige} />
            <img src={Sweden} />
          </div>
          <div className={style.row}>
            <img src={Canada} />
            <img src={Goethe} />
            <img src={HaberTurk} />
            <img src={Pencere} />
            <img src={Sverige} />
            <img src={Sweden} />
          </div>
          <div className={style.row}>
            <img src={Canada} />
            <img src={Goethe} />
            <img src={HaberTurk} />
            <img src={Pencere} />
            <img src={Sverige} />
            <img src={Sweden} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
