import { PageWrapper, MovieRow, PdfButton } from '../../components';
import Bella from '../../assets/img/dummy/bella.png';
import Eclipse from '../../assets/img/dummy/eclipse.png';
import How from '../../assets/img/dummy/how.png';
import Jelinek from '../../assets/img/dummy/jelinek.png';
import JellyFish from '../../assets/img/dummy/jellyfish.png';
import Years from '../../assets/img/dummy/years.png';

const data = [
  {
    title: 'Elfriede Jelinek: Dili Esaretinden Kurtarmak',
    poster: Bella,
    duration: '69’',
    year: '2023',
    category: 'İranlı Kadınlar Konuşuyor: Tarih, Sanat, Direniş',
    type: '',
    color: 'orange',
  },
  {
    title: 'Elfriede Jelinek: Dili Esaretinden Kurtarmak',
    poster: Eclipse,
    duration: '69’',
    year: '2023',
    category: 'İranlı Kadınlar Konuşuyor: Tarih, Sanat, Direniş',
    type: '',
    color: 'orange',
  },
  {
    title: 'Elfriede Jelinek: Dili Esaretinden Kurtarmak',
    poster: How,
    duration: '69’',
    year: '2023',
    category: 'İranlı Kadınlar Konuşuyor: Tarih, Sanat, Direniş',
    type: '',
    color: 'orange',
  },
  {
    title: 'Elfriede Jelinek: Dili Esaretinden Kurtarmak',
    poster: Jelinek,
    duration: '69’',
    year: '2023',
    category: 'İranlı Kadınlar Konuşuyor: Tarih, Sanat, Direniş',
    type: '',
    color: 'orange',
  },
  {
    title: 'Elfriede Jelinek: Dili Esaretinden Kurtarmak',
    poster: JellyFish,
    duration: '69’',
    year: '2023',
    category: 'İranlı Kadınlar Konuşuyor: Tarih, Sanat, Direniş',
    type: '',
    color: 'orange',
  },
  {
    title: 'Elfriede Jelinek: Dili Esaretinden Kurtarmak',
    poster: Years,
    duration: '69’',
    year: '2023',
    category: 'İranlı Kadınlar Konuşuyor: Tarih, Sanat, Direniş',
    type: '',
    color: 'orange',
  },
];

const Session = () => {
  return (
    <PageWrapper>
      <PdfButton />
      <MovieRow data={data} title={'YENİ GÖSTERİMLER'} />
      <MovieRow data={data} type={'date'} date={'1 HAZİRAN'} day={'PERŞEMBE'} />
      <MovieRow
        title={'İranlı Kadınlar Konuşuyor: Tarih, Sanat, Direniş'}
        color={'orange'}
        type={'category'}
        data={data}
      />
    </PageWrapper>
  );
};

export default Session;
