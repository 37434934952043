const initialState = {
  settings: {},
};

export default function mainReducer(state = initialState, action) {
  switch (action.type) {
    case 'MAIN':
      return {
        ...state,
        settings: { ...state.search, date: action.payload },
      };

    default:
      return state;
  }
}
