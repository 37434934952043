import style from './moviecard.module.scss';

const MovieCard = ({
  poster,
  type,
  duration,
  year,
  category,
  color,
  title,
  session,
}) => {
  return (
    <a href="/gosterim/jelly" className={style.movieCard}>
      <img src={poster} alt="poster" />
      <h4>{title}</h4>

      {type === 'session' ? (
        <div className={style.sessionWrapper}>
          {session.map(item => {
            return <a>{item}</a>;
          })}
        </div>
      ) : (
        <>
          <h5>
            {duration} | {year}
          </h5>
          <h6 className={style[color]}>{category}</h6>
        </>
      )}
    </a>
  );
};

export default MovieCard;
