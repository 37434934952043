import { PageWrapper, SessionButton } from '../../components';
import style from './directordetail.module.scss';
import Photo from '../../assets/img/dummy/idil.png';
import Illustration from '../../assets/img/director-illustration.png';
import Poster from '../../assets/img/dummy/jellyfish-original.png';

const DirectorDetail = () => {
  return (
    <PageWrapper>
      <div className={style.wrapper}>
        <div className={style.top}>
          <img src={Photo} className={style.main} />
          <div className={style.right}>
            <div className={style.info}>
              <h1>İDİL AKKUŞ</h1>
              <p>
                Gençliğinden itibaren sinemaya hayranlık duyan Joanna Hogg
                1980’lerde İngiltere’de Ulusal Sinema ve Televizyon Okulu’nda
                sinema eğitimi aldı, kendini ne katı toplumcu gerçekçi ne de saf
                peri masalı olan hikayeler anlatmanın yolunu ararken buldu.
                Eğitiminin ardından müzik klipleri ve televizyon yapımlarında
                yönetmenlik yaparken bir yandan da sanat ve edebiyata olan
                açlığını doyurdu, kendi hikayelerini anlatmaya hazır olana kadar
                bekledi. İlk uzun metraj filmi olan Unrelated’ı 2007’de çekti.
                İtalya’da ailesinden uzakta tatil yapan bir kadının hikayesini
                anlatan film Hogg’u sinemada yeni ve güçlü ses olarak
                izleyicilere tanıttı. Unrelated’ın ardından bir anne ve iki
                yetişkin çocuğunun İngiltere’nin ücra bir adasındaki tatil
                evinde birbirleriyle cebelleştiği, büyük övgü alan Archipelago
                geldi. Hogg, 2014 tarihli Exhibition’da iki orta yaşlı
                sanatçının modernist mimarili evlerini satarken anılarıyla
                yüzleşmesi üzerinden insanların içinde yaşadıkları mekanların
                kişisel hikayeleriyle çarpışmasını ele aldı.
              </p>
            </div>
            <div className={style.movie}>
              <img src={Poster} alt="poster" />
              <div className={style.movieInfo}>
                <h3>Elfriede Jelinek: Dili Esaretinden Kurtarmak</h3>
                <h4>69’ | 2023</h4>
                <h5>İranlı Kadınlar Konuşuyor: Tarih, Sanat, Direniş </h5>
                <div className={style.sessionWrapper}>
                  <div className={style.session}>
                    <h6>
                      1 HAZİRAN | <span>PERŞEMBE</span>
                    </h6>
                    <div className={style.buttonWrapper}>
                      <SessionButton text={'11:30'} color={'blue'} />
                      <SessionButton text={'14:30'} color={'blue'} />
                      <SessionButton text={'16:30'} color={'blue'} />
                    </div>
                  </div>
                  <div className={style.session}>
                    <h6>
                      1 HAZİRAN | <span>PERŞEMBE</span>
                    </h6>
                    <div className={style.buttonWrapper}>
                      <SessionButton text={'11:30'} color={'blue'} />
                      <SessionButton text={'14:30'} color={'blue'} />
                      <SessionButton text={'16:30'} color={'blue'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.bottom}>
          <img src={Illustration} className={style.illustration} />
        </div>
      </div>
    </PageWrapper>
  );
};

export default DirectorDetail;
