import { PageWrapper, DirectorCard } from '../../components';
import Photo from '../../assets/img/dummy/idil.png';
import style from './directors.module.scss';

const Directors = () => {
  return (
    <PageWrapper>
      <div className={style.wrapper}>
        <DirectorCard image={Photo} title={'İDİL AKKUŞ'} />
        <DirectorCard image={Photo} title={'İDİL AKKUŞ'} />
        <DirectorCard image={Photo} title={'İDİL AKKUŞ'} />
        <DirectorCard image={Photo} title={'İDİL AKKUŞ'} />
        <DirectorCard image={Photo} title={'İDİL AKKUŞ'} />
        <DirectorCard image={Photo} title={'İDİL AKKUŞ'} />
        <DirectorCard image={Photo} title={'İDİL AKKUŞ'} />
        <DirectorCard image={Photo} title={'İDİL AKKUŞ'} />
        <DirectorCard image={Photo} title={'İDİL AKKUŞ'} />
        <DirectorCard image={Photo} title={'İDİL AKKUŞ'} />
        <DirectorCard image={Photo} title={'İDİL AKKUŞ'} />
        <DirectorCard image={Photo} title={'İDİL AKKUŞ'} />
        <DirectorCard image={Photo} title={'İDİL AKKUŞ'} />
      </div>
    </PageWrapper>
  );
};

export default Directors;
