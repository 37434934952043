import style from './widget.module.scss';
import SessionButton from '../Elements/SessionButton';

const HomeWidget = ({ date, day }) => {
  return (
    <div className={style.widget}>
      <h3>YAKINDAKİ SEANSLAR</h3>
      <h4>
        {date} <span>{day}</span>
      </h4>
      <button className={style.acordion}>
        Salon 1 <span>-</span>
      </button>
      <ul>
        <li>
          <h5>Elfriede Jelinek: Dili Esaretinden Kurtarmak</h5>
          <h5>Elfriede Jelinek: Language Unleashed</h5>
          <h5>Elfriede Jelinek die Sprache von der Leine lassen</h5>
          <div className={style.buttonWrapper}>
            <SessionButton text={'11:30'} color={'blue'} />
            <SessionButton text={'14:30'} color={'blue'} />
            <SessionButton text={'16:30'} color={'blue'} />
          </div>
        </li>
        <li>
          <h5>Elfriede Jelinek: Dili Esaretinden Kurtarmak</h5>
          <h5>Elfriede Jelinek: Language Unleashed</h5>
          <h5>Elfriede Jelinek die Sprache von der Leine lassen</h5>
          <div className={style.buttonWrapper}>
            <SessionButton text={'11:30'} color={'blue'} />
            <SessionButton text={'14:30'} color={'blue'} />
            <SessionButton text={'16:30'} color={'blue'} />
          </div>
        </li>
        <li>
          <h5>Elfriede Jelinek: Dili Esaretinden Kurtarmak</h5>
          <h5>Elfriede Jelinek: Language Unleashed</h5>
          <h5>Elfriede Jelinek die Sprache von der Leine lassen</h5>
          <div className={style.buttonWrapper}>
            <SessionButton text={'11:30'} color={'blue'} />
            <SessionButton text={'14:30'} color={'blue'} />
            <SessionButton text={'16:30'} color={'blue'} />
          </div>
        </li>
      </ul>
      <button className={style.acordion}>
        Salon 2 <span>+</span>
      </button>
      <button className={style.acordion}>
        Salon 3 <span>+</span>
      </button>
    </div>
  );
};

export default HomeWidget;
