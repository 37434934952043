import style from './footer.module.scss';

const Footer = () => {
  return (
    <footer className={style.footer}>
      <div className={style.wrapper}>
        <ul>
          <li>
            <a href="/">UÇAN SÜPÜRGE 2023</a>
          </li>
          <li>
            <a href="/">GÖSTERİMLER</a>
          </li>
          <li>
            <a href="/">BİLETLER</a>
          </li>
          <li>
            <a href="/">YÖNETMENLER</a>
          </li>
          <li>
            <a href="/">İLETİŞİM</a>
          </li>
        </ul>
        <h5>Copyright, All Rights Reserved | Uçan Süpürge</h5>
      </div>
    </footer>
  );
};

export default Footer;
