import {
  Home,
  Session,
  Tickets,
  MovieDetail,
  Directors,
  DirectorDetail,
  About,
  Contact,
} from '../containers';

export const routes = [
  {
    title: 'Home',
    path: '/',
    component: <Home route={''} />,
    protected: false,
  },
  {
    title: 'Gösterimler',
    path: '/gosterimler',
    component: <Session />,
    protected: false,
  },
  {
    title: 'Biletler',
    path: '/biletler',
    component: <Tickets />,
    protected: false,
  },
  {
    title: 'Biletler',
    path: '/gosterim/:id',
    component: <MovieDetail />,
    protected: false,
  },
  {
    title: 'Yonetmenler',
    path: '/yonetmenler',
    component: <Directors />,
    protected: false,
  },
  {
    title: 'Biletler',
    path: '/yonetmen/:id',
    component: <DirectorDetail />,
    protected: false,
  },
  {
    title: 'About',
    path: '/ucan-supurge-2023',
    component: <About />,
    protected: false,
  },
  {
    title: 'Contact',
    path: '/iletisim',
    component: <Contact />,
    protected: false,
  },
];
