import { PdfIcon } from '../../assets/icon';
import style from './pdfbutton.module.scss';

const PdfButton = () => {
  return (
    <div className={style.pdfButton}>
      <button>
        <PdfIcon /> Gösterimlerin çizelgesini indirmek için tıklayabilirsiniz.
      </button>
    </div>
  );
};

export default PdfButton;
