import style from './directorcard.module.scss';

const DirectorCard = ({ image, title }) => {
  return (
    <a href="/yonetmen/idil" className={style.directorCard}>
      <div className={style.image}>
        <div className={style.circle}>
          BİYO
          <br />
          GRAFİ
        </div>
        <img src={image} alt={title} />
      </div>
      <h5>{title}</h5>
    </a>
  );
};

export default DirectorCard;
