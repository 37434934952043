import { PageWrapper } from '../../components';
import style from './about.module.scss';
import HeadImage from '../../assets/img/head-image.png';
import Decor from '../../assets/img/about-decor.png';
import AboutIllustration from '../../assets/img/about-illustration.png';

const About = () => {
  return (
    <PageWrapper>
      <div className={style.wrapper}>
        <img className={style.decor} src={Decor} alt="decor" />
        <div className={style.info}>
          <img src={HeadImage} alt="head" />
          <p>
            26 yıldır seçkisinde kadın sinemacıların filmlerine yer veren Uçan
            Süpürge Uluslararası Kadın Filmleri Festivali, köklü tarihinde
            2000’den fazla filmi izleyicilerle buluşturdu.
            <br />
            <br />
            Kadın sinemacıları görünür kılmak amacıyla yola çıkan festival, 26.
            yılında 50’den fazla ulusal ve uluslararası belgesel, uzun metraj ve
            kısa metraj filmi, 80 seansta 31 Mayıs – 7 Haziran 2023 tarihlerinde
            Büyülü Fener Kızılay Sineması’nda gösterecek. Programını film
            gösterimleri sonrası soru cevaplar ve panellerle destekleyen
            festival, bu yıl da dünya sinemasının saygın ödüllere sahip
            filmlerinin yanı sıra Türkiye’den dikkat çeken filmlerden oluşan bir
            seçkiyi izleyici karşısına çıkarak.
          </p>
          <img src={AboutIllustration} className={style.aboutImage} />
        </div>
      </div>
    </PageWrapper>
  );
};

export default About;
