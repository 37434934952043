import { PageWrapper, SessionButton } from '../../components';
import Poster from '../../assets/img/dummy/jellyfish-original.png';
import style from './moviedetail.module.scss';

const MovieDetail = () => {
  return (
    <PageWrapper>
      <div className={style.wrapper}>
        <img src={Poster} alt="poster" />
        <div className={style.info}>
          <h1>Elfriede Jelinek: Dili Esaretinden Kurtarmak</h1>
          <p>
            A ballroom dancer experiences an unexpected and whirlwind romance
            over the course of 24 hours, in this second feature from
            writer-director Goran Stolevski.
          </p>
          <ul>
            <li>
              <h5>Director</h5>
              <h6>Goran Stolevski</h6>
            </li>
            <li>
              <h5>Actor</h5>
              <h6>Goran Stolevski, Goran Stolevski, Goran Stolevski, </h6>
            </li>
            <li>
              <h5>Kategori</h5>
              <h6>İranlı Kadınlar Konuşuyor: Tarih, Sanat, Direniş</h6>
            </li>
            <li>
              <h5>Süre</h5>
              <h6>72’</h6>
            </li>
          </ul>
        </div>
        <div className={style.sessionWrapper}>
          <div className={style.session}>
            <h5>
              1 HAZİRAN | <span>PERŞEMBE</span>
            </h5>
            <div className={style.buttonWrapper}>
              <SessionButton text={'11:30'} color={'blue'} />
              <SessionButton text={'14:30'} color={'blue'} />
              <SessionButton text={'16:30'} color={'blue'} />
            </div>
          </div>
          <div className={style.session}>
            <h5>
              2 HAZİRAN | <span>PERŞEMBE</span>
            </h5>
            <div className={style.buttonWrapper}>
              <SessionButton text={'11:30'} color={'blue'} />
              <SessionButton text={'14:30'} color={'blue'} />
              <SessionButton text={'16:30'} color={'blue'} />
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default MovieDetail;
