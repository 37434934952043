import { Logo, SearchIcon } from '../../assets/icon';
import style from './header.module.scss';

const Header = () => {
  return (
    <header className={style.header}>
      <div className={style.wrapper}>
        <a href="/" className={style.logo}>
          <Logo />
        </a>
        <ul>
          <li>
            <a href="/ucan-supurge-2023">UÇAN SÜPÜRGE 2023</a>
          </li>
          <li>
            <a href="/gosterimler">GÖSTERİMLER</a>
          </li>
          <li>
            <a href="/biletler">BİLETLER</a>
          </li>
          <li>
            <a href="/yonetmenler">YÖNETMENLER</a>
          </li>
          <li>
            <a href="/iletisim">İLETİŞİM</a>
          </li>
        </ul>
        <label>
          <input type="text" placeholder="Film,yonetmen..." />
          <button>
            <SearchIcon />
          </button>
        </label>
      </div>
    </header>
  );
};

export default Header;
