import style from './movierow.module.scss';
import MovieCard from '../MovieCard/MovieCard';

const MovieRow = ({ type, title, date, day, color, data }) => {
  return (
    <div className={style.movieRow}>
      {type === 'date' ? (
        <h4 className={style.title}>
          {date} <span>{day}</span>{' '}
        </h4>
      ) : type === 'category' ? (
        <h4 className={`${style.title} ${style[color]}`}>{title}</h4>
      ) : (
        <h4 className={style.title}>{title}</h4>
      )}
      <div className={style.movieWrapper}>
        {data.map(item => {
          return (
            <MovieCard
              poster={item.poster}
              type={item.type}
              duration={item.duration}
              year={item.year}
              category={item.category}
              color={item.color}
              title={item.title}
              session={item.session}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MovieRow;
