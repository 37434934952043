import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import style from './pagewrapper.module.scss';

const PageWrapper = ({ children }) => {
  return (
    <div className={style.page}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default PageWrapper;
