import { PageWrapper, HomeWidget, Support, Contact } from '../../components';
import style from './home.module.scss';
import HeadImage from '../../assets/img/head-image.png';

const Home = () => {
  return (
    <PageWrapper>
      <div className={style.wrapper}>
        <div className={style.top}>
          <HomeWidget date={'1 Haziran'} day={'Perşembe'} />
          <div className={style.head}>
            <img src={HeadImage} />
            <p>
              26 yıldır seçkisinde kadın sinemacıların filmlerine yer veren Uçan
              Süpürge Uluslararası Kadın Filmleri Festivali, köklü tarihinde
              2000’den fazla filmi izleyicilerle buluşturdu.
              <br />
              <br />
              Kadın sinemacıları görünür kılmak amacıyla yola çıkan festival,
              26. yılında 50’den fazla ulusal ve uluslararası belgesel, uzun
              metraj ve kısa metraj filmi, 80 seansta 31 Mayıs – 7 Haziran 2023
              tarihlerinde Büyülü Fener Kızılay Sineması’nda gösterecek.
              Programını film gösterimleri sonrası soru cevaplar ve panellerle
              destekleyen festival, bu yıl da dünya sinemasının saygın ödüllere
              sahip filmlerinin yanı sıra Türkiye’den dikkat çeken filmlerden
              oluşan bir seçkiyi izleyici karşısına çıkarak.
            </p>
          </div>
        </div>
      </div>
      <Support />

      <Contact />
    </PageWrapper>
  );
};

export default Home;
