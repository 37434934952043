import { SocialIcon, Logo } from '../../assets/icon';
import style from './contact.module.scss';
import Illustration from '../../assets/img/eye-illustration.png';

const Contact = () => {
  return (
    <div className={style.contact}>
      <div className={style.logo}>
        <Logo />
      </div>
      <div className={style.info}>
        <h2>İLETİŞİM</h2>
        <h3>
          <span>T:</span> +90 (312) 426 97 12
        </h3>
        <h3>
          <span>T:</span> +90 533 762 31 17
        </h3>
        <h3>
          <span>EMAIL:</span> info@ucansupurge.org
        </h3>
        <h3>
          <span>ADRES:</span> Gaziosmanpaşa Mahallesi, <br />
          Nenehatun Caddesi, Şairler Sokak, <br />
          No: 7/4 Çankaya, <br />
          06680 - Ankara/Turkey <br />
        </h3>
        <div className={style.socialMedia}>
          <a href="/">
            <SocialIcon type={'facebook'} />
          </a>
          <a href="/">
            <SocialIcon type={'twitter'} />
          </a>
          <a href="/">
            <SocialIcon type={'instagram'} />
          </a>
        </div>
      </div>
      <img src={Illustration} className={style.illustration} />
    </div>
  );
};

export default Contact;
